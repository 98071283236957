import Vue from 'vue';
import VueRouter from 'vue-router';


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../components/index.vue')
  },
  {
    path: '/Building',
    name: 'Building',
    component: () => import('../components/GuidBuilding.vue')
  },
  {
    path: '/WorkPress',
    name: 'WorkPress',
    component: () => import('../components/workPress.vue')
  },
  {
    path: '/DisVideo',
    name: 'DisVideo',
    component: () => import('../components/disVideo.vue')
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

export default router;
